export default [
  { title: "Dashboard", icon: "$dashboard", link: "/dashboard" },
  { title: "Kelola Program", icon: "$program", link: "/program" },
  // { title: "Reseller", icon: "ri-user-line", link: "/daftar-reseller" },
  // { title: "Notifikasi", icon: "ri-chat-settings-line", link: "/notifikasi" },
  { title: "Data Detail Test", icon: "ri-gallery-line", link: "/results" },
  { title: "Presensi", icon: "ri-list-check-2", link: "/presensi" },
  { title: "Import Data", icon: "ri-upload-cloud-2-line", link: "/import" },
  // { title: "Sertifikat", icon: "ri-award-fill", link: "/update-content" },
];
