<template>
  <v-data-table
    :loading="isLoading"
    :headers="headers"
    :items="data.list"
    :items-per-page.sync="data.limit"
    :page.sync="data.page"
    :item-key="'id'"
    class="transparent"
    :mobile-breakpoint="0"
    :server-items-length="data.total"
    loading-text="Loading... Please wait"
    :footer-props="{
      'items-per-page-text':'Row per page : '
    }"
  >
    <template slot="item" slot-scope="{ item }">
      <tr class="flat-b-1 rounded-lg">
        <td class="white text-capitalize" v-text="item.license"></td>
        <td class="white text-capitalize" v-text="item.program.title" style="width: 50%"></td>
        <td class="white text-capitalize" v-text="item.member.fullname" style="width: 30%"></td>
        <td class="white text-capitalize" v-text="item.member.phone"></td>
        <td class="white text-capitalize" style="width: 30%">
          {{ currentDateTime(item.start_test_at) }}
        </td>
        <td class="white text-capitalize">
          <v-switch
            v-if="item.meta.gift === 'true'"
            :input-value="true"
             @click="$emit('change', item )"
          ></v-switch>
            <!-- v-model="cekTrue" -->
          <v-switch
            v-else
            :input-value="false"            
             @click="$emit('change', item )"
          ></v-switch>
            <!-- v-model="cekFalse" -->
        </td>
      </tr>
    </template>

  </v-data-table>
</template>
<script>
import moment from "moment";

export default {
  name: "reseller-table",
  props: {
    // data: { required: true, type: Object, default: () => ({}) },
    data: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: () => false },
  },
  data: () => ({
    headers: [
      { text: "Kode Voucher", class: "text-no-wrap", sortable: false, },
      { text: "Nama Program", class: "text-no-wrap", sortable: false, },
      { text: "Nama Pengguna", class: "text-no-wrap", sortable: false, },
      { text: "No WA", class: "text-no-wrap", sortable: false, },
      { text: "Tanggal Redeem", class: "text-no-wrap", sortable: false, },
      { text: "", class: "text-no-wrap", sortable: false, },
    ],
    item:[
      {
        code:'16AE41332TY90',
        name_prog:'Menggunakan Bahasa Inggris Untuk Operator Layanan Penjawab',
        name_user:' Linda Purnama Sari',
        no_wa:'08123456789',
        date:'Feb 2, 2019 19:28',
        status:true
      },
      {
        code:'16AE41332TY91',
        name_prog:'Menggunakan Bahasa Inggris Untuk Operator Layanan Penjawab',
        name_user:' Budi Purnomo',
        no_wa:'08123456789',
        date:'Feb 2, 2019 19:28',
        status:false
      },       
    ],
    table_loading: false,
    cekFalse:false,
    cekTrue:true
  }),
  computed: {
    isLoading() {
      return this.loading || this.table_loading;
    },
  },
  methods: {
    paginate: (current, total) => {
      if (current > total) {
        throw new Error("Current page is larger than total");
      }
      let pages = [];
      if (total > 5) {
        pages = new Array(5);
        pages = pages.fill(0).map((val, i) => {
          if (current > 3 && current < total - 2) {
            switch (i) {
              case 0:
                return current > 3 ? "..." : i + current - 2;
              case 4:
                return current < total - 2 ? "..." : i + current - 2;
              default:
                return i + current - 2;
            }
          }

          if (current <= 3) {
            return i == 4 ? "..." : i + 1;
          }

          if (current >= total - 2) {
            return i == 0 ? "..." : i + total - 4;
          }
        });
      } else {
        pages = new Array(total);
        pages = pages.fill(0).map((val, i) => i + 1);
      }

      return pages;
    },
    currentDateTime(a) {
      return moment(a).format("LLL");
    },

  },
  watch: {
    "data.limit": function () {
      this.$emit("refresh-list");
    },
    "data.page": function () {
      this.$emit("refresh-list");
    },
    // "data": function(){
    //   this.$emit("refresh-list");      
    // },
    // "cekFalse": function(){
    //   this.$emit("refresh-list");      
    // }
  }
};
</script>

<style scoped>
  #dt-program .v-data-table > .v-data-table__wrapper > table {
    border-spacing: 0 10px !important;
  }
  #dt-program .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 80px !important;
  }
  .theme--light #dt-program .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th{
    border:0px solid!important;
  }
  .theme--light #dt-program .v-data-table .v-data-footer {
    border:0px solid!important;    
  }
  #dt-program .v-data-footer__select {
    justify-content: left !important;
    position: absolute;
    left: 2%;
  }
  .v-text-field--outlined fieldset{
    border:0px solid;
  }
  .v-application--is-ltr #dt-program .v-data-footer__select .v-select {
    margin: 13px 0 13px 0px;
  }
</style>