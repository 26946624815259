<template>
  <v-col cols="12" v-bind="$attrs" v-on="$listeners">
    <v-card outlined>
      <v-row v-if="loading" class="py-4 px-8">
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate color="primary" />
        </v-col>
      </v-row>
      <template v-else>
        <v-row class="py-4 px-8">
          <v-col>
            <v-tabs v-model="tab">
              <v-tab
                class="pa-1 mr-2 text-capitalize"
                v-for="tab in tabs"
                :key="tab"
              >
                <span>
                  {{ tab }}
                </span>
              </v-tab>
            </v-tabs>
            <div class="pt-8">
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <div class="font-weight-bold">Aktivitas {{ seq }}</div>
                  <v-row>
                    <v-col cols="12">
                      <div class="font-weight-medium mb-2">Judul Aktivitas</div>
                      <v-text-field
                        outlined
                        placeholder="Masukkan judul aktivitas"
                        v-model="activity.title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <div class="font-weight-medium">Jenis Aktivitas</div>
                      <v-select
                        :disabled="$route.name == 'activity-edit'"
                        :items="types"
                        item-text="text"
                        item-value="value"
                        v-model="activity.type"
                      ></v-select>
                    </v-col>
                    <v-col v-if="isAbleToClone" cols="12">
                      <v-checkbox v-model="activity.is_clone">
                        <span slot="label" class="color--text"
                          >Clone pre test</span
                        >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <setting-quiz
                    v-if="Question2Setting"
                    v-model="activity.settings"
                  />
                </v-tab-item>
                <v-tab-item>
                  <content-quiz
                    v-if="Question2Setting"
                    ref="question"
                    v-model="activity.theory"
                  />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
        </v-row>
        <v-progress-linear
          v-if="submiting"
          :value="progress"
        ></v-progress-linear>
        <v-card-actions class="background">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="submiting"
            outlined
            @click="$emit('click:cancel')"
            >Batal</v-btn
          >
          <v-btn color="primary" :loading="submiting" @click="handleSubmit"
            >Simpan</v-btn
          >
        </v-card-actions>
      </template>
    </v-card>
  </v-col>
</template>

<script>
import SettingQuiz from "./Settings/Question";
import ContentQuiz from "./Contents/Question";

export default {
  components: {
    SettingQuiz,

    ContentQuiz,
  },
  props: {
    activity: { type: Object, required: true },
    seq: { type: [Number, String], required: true },
    submiting: Boolean,
  },
  data: () => ({
    tab: null,
    loading: null,
    clone: {},
    progress: null,
  }),
  computed: {
    tabs() {
      const tab =
        this.QuestionSetting ||
        this.Question2Setting ||
        this.Question3Setting ||
        this.Question4Setting
          ? "Pertanyaan"
          : "Konten";
      return ["Detail", "Pengaturan", tab];
    },
    types() {
      return [{ value: "quiz", text: "Quiz" }];
    },
    isAbleToClone() {
      return (
        this.$route.name == "activity-create" &&
        ["post_test"].includes(this.activity.type) &&
        this.clone.type == "pre_test"
      );
    },
    Question2Setting() {
      return this.activity.type == this.types[0].value;
    },
  },
  watch: {
    activity: {
      handler() {
        this.$emit("update:activity", this.activity);
      },
      deep: true,
    },
  },
  methods: {
    async handleSubmit() {
      if (this.$refs.question) {
        await this.$refs.question
          .saveQuestions()
          .then(() => {
            this.$emit("click:submit", this.activity);
          })
          .catch((error) => {
            console.error(error);
            this.$emit("click:submit", error);
          });
      } else {
        this.$emit("click:submit", this.activity);
      }
    },
    async handleUpload(progress) {
      this.progress = (progress.loaded / progress.total) * 99;
    },
    normalizeSize(byte) {
      let units = "";
      if (byte / 1024 > 0.2) {
        byte = (byte / 1024).toFixed(2);
        units = "KB";
      }
      if (byte / 1024 > 0.2) {
        byte = (byte / 1024).toFixed(2);
        units = "MB";
      }
      if (byte / 1024 > 0.2) {
        byte = (byte / 1024).toFixed(2);
        units = "GB";
      }
      return `${byte}${units}`;
    },
  },
  async created() {
    this.loading = true;
    const cloneData = await this.axios
      .get(
        `/program/v1/company/check-available-prepost/${this.$route.params.program}`
      )
      .then((res) => res.data);
    this.clone = cloneData.data;
    if (
      ["quiz", "pre_test", "post_test", "exam"].includes(this.activity.type)
    ) {
      await this.$store.dispatch("packet/get", {
        program: this.$route.params.program,
        topic: this.$route.params.topic,
        activity: this.$route.params.id,
        params: { order_by: "created_at", dir: "asc" },
      });
    }
    this.loading = false;
  },
};
</script>
