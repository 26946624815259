<template>
  <v-data-table
    :loading="isLoading"
    :headers="headers"
    :items="data.list"
    :items-per-page.sync="data.limit"
    :page.sync="data.page"
    :item-key="'id'"
    class="transparent"
    :mobile-breakpoint="0"
    :server-items-length="data.total_data"
    loading-text="Loading... Please wait"
    :footer-props="{
      'items-per-page-text':'Row per page : '
    }"
  >
    <template slot="item" slot-scope="{ item }">
      <tr id="table-notif" class="flat-b-1 rounded-lg">
        <td class="white text-capitalize" style="width: 20%">
          <p v-if="item.type === 'global'">Semua user</p>
          <p v-else>Berdasarkan program/progress</p>
        </td>
        <td class="white text-capitalize" style="width: 20%" v-text="item.program != null ? item.program.title : null"></td>
        <td class="white text-capitalize" style="width: 10%" v-text="item.progress != null ? item.progress.progress_start : null"></td>
        <td class="white text-capitalize" style="width: 10%" v-text="item.progress != null ? item.progress.progress_end : null"></td>
        <td class="white text-capitalize" style="width: 10%">{{currentDateTime(item.created_at)}}</td>
        <td class="white text-capitalize" style="width: 50%">
          <v-expansion-panels>
            <v-expansion-panel
            >
              <v-expansion-panel-header class="ps-0">
                <p class="mb-0" style="height:30px;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{item.title}}</p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{item.description}}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </td>
        <!-- <td class="white rounded-r-lg" style="width: 5%">
          <div class="d-flex flex-row">
            <v-btn icon @click="$emit('remove', item)"><v-icon>ri-delete-bin-6-line</v-icon> </v-btn>            
          </div>
        </td> -->
      </tr>
    </template>

  </v-data-table>
</template>
<script>
import moment from "moment";

export default {
  name: "program-table",
  props: {
    // data: { required: true, type: Object, default: () => ({}) },
    data: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: () => false },
  },
  data: () => ({
    headers: [
      { text: "Target", class: "text-no-wrap", sortable: false, },
      { text: "Nama Program", class: "text-no-wrap", sortable: false, },
      { text: "Progress Min(%)", class: "text-no-wrap", sortable: false, },
      { text: "Progress Maks(%)", class: "text-no-wrap", sortable: false, },
      { text: "Dikirim pada", class: "text-no-wrap", sortable: false, },
      { text: "Pesan", class: "text-no-wrap", sortable: false, },
      // { text: "Aksi", class: "text-no-wrap" },
    ],
    item:[
      {
        target:'All User',
        waktu:'20 Oktober 2021 15:30:04',
        pesan:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        // pre:80,
        // post:92
      },      
    ],
    table_loading: false,
  }),
  computed: {
    isLoading() {
      return this.loading || this.table_loading;
    },
  },
  methods: {
    currentDateTime(a) {
      return moment(a).locale('ID').format("ll");
    },
    paginate: (current, total) => {
      if (current > total) {
        throw new Error("Current page is larger than total");
      }
      let pages = [];
      if (total > 5) {
        pages = new Array(5);
        pages = pages.fill(0).map((val, i) => {
          if (current > 3 && current < total - 2) {
            switch (i) {
              case 0:
                return current > 3 ? "..." : i + current - 2;
              case 4:
                return current < total - 2 ? "..." : i + current - 2;
              default:
                return i + current - 2;
            }
          }

          if (current <= 3) {
            return i == 4 ? "..." : i + 1;
          }

          if (current >= total - 2) {
            return i == 0 ? "..." : i + total - 4;
          }
        });
      } else {
        pages = new Array(total);
        pages = pages.fill(0).map((val, i) => i + 1);
      }

      return pages;
    },
  },
  watch: {
    "data.limit": function () {
      this.$emit("refresh-list");
    },
    "data.page": function () {
      this.$emit("refresh-list");
    },
  }
};
</script>
<style scoped>
  #dt-program .v-data-table > .v-data-table__wrapper > table {
    border-spacing: 0 10px !important;
  }
  #dt-program .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 80px !important;
  }
  .theme--light #dt-program .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th{
    border:0px solid!important;
  }
  .theme--light #dt-program .v-data-table .v-data-footer {
    border:0px solid!important;    
  }
  #dt-program .v-data-footer__select {
    justify-content: left !important;
    position: absolute;
    left: 2%;
  }
  #table-notif .v-expansion-panel-content__wrap{padding-left: 0px!important;}
  .v-text-field--outlined fieldset{
    border:0px solid;
  }
  .v-application--is-ltr #dt-program .v-data-footer__select .v-select {
    margin: 13px 0 13px 0px;
  }
</style>