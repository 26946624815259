<template>
  <v-data-table
    :loading="isLoading"
    :headers="headers"
    :items="data.list"
    :items-per-page.sync="data.limit"
    :page.sync="data.page"
    :item-key="'id'"
    class="transparent"
    :mobile-breakpoint="0"
    :server-items-length="data.total"
    loading-text="Loading... Please wait"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :footer-props="{
      'items-per-page-text':'Row per page : '
    }"
  >
    <template v-slot:header.total="{ header }">
      <th v-if="sortDesc===false" @click="sortCalories" style="cursor: pointer;">{{ header.text }} <v-icon small>ri-arrow-up-line</v-icon></th>
      <th v-else @click="sortCalories" style="cursor: pointer;">{{ header.text }} <v-icon small>ri-arrow-down-line</v-icon></th>
    </template>
    <template slot="item" slot-scope="{ item }">
      <tr class="flat-b-1 rounded-lg">
        <td class="white text-capitalize" v-text="item.fullname" style="width: 50%"></td>
        <td class="white text-capitalize" v-text="item.referral.code">
        </td>
        <td class="white text-capitalize">
          <router-link
            class="primary--text text-decoration-underline text-capitalize"
            :to="{ name: 'list-referal', params: { id: item.referral.code } }"
            v-text="item.referral.total_user"
          >
          </router-link>
        </td>
        <td class="white text-capitalize" style="width: 5%;"> 
          <v-btn @click="$emit('change', item.id)" icon><v-icon>ri-pencil-line</v-icon> </v-btn>          
        </td>
      </tr>
    </template>

  </v-data-table>
</template>
<script>
export default {
  name: "reseller-table",
  props: {
    // data: { required: true, type: Object, default: () => ({}) },
    data: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: () => false },
  },
  data: () => ({
    sortBy: "total",
    sortDesc: false,
    headers: [
      { text: "Nama Reseller", value:'name',class: "text-no-wrap",sortable: false, },
      { text: "Kode Referral", value:'code',class: "text-no-wrap",sortable: false, },
      { text: "Total Pengguna", value:'total',class: "text-no-wrap",sortable: false, },
      { text: "Aksi", class: "text-no-wrap",sortable: false, },
    ],
    item:[
      {
        name:'Darlene',
        code:'123456',
        total:'200'
      },
      {
        name:'Budi Permana',
        code:'1234567',
        total:'100'
      },       
    ],
    table_loading: false,
  }),
  computed: {
    isLoading() {
      return this.loading || this.table_loading;
    },
  },
  methods: {
    sortCalories() {
      if (this.sortDesc === false) {
        this.sortDesc = true;
      }else{
        this.sortDesc = false;        
      }
      console.log('sort',this.sortDesc)
      this.$emit("sorting",this.sortDesc);
    },
    paginate: (current, total) => {
      if (current > total) {
        throw new Error("Current page is larger than total");
      }
      let pages = [];
      if (total > 5) {
        pages = new Array(5);
        pages = pages.fill(0).map((val, i) => {
          if (current > 3 && current < total - 2) {
            switch (i) {
              case 0:
                return current > 3 ? "..." : i + current - 2;
              case 4:
                return current < total - 2 ? "..." : i + current - 2;
              default:
                return i + current - 2;
            }
          }

          if (current <= 3) {
            return i == 4 ? "..." : i + 1;
          }

          if (current >= total - 2) {
            return i == 0 ? "..." : i + total - 4;
          }
        });
      } else {
        pages = new Array(total);
        pages = pages.fill(0).map((val, i) => i + 1);
      }

      return pages;
    },
  },
  watch: {
    "data.limit": function () {
      this.$emit("refresh-list");
    },
    "data.page": function () {
      this.$emit("refresh-list");
    },
  }
};
</script>

<style scoped>
  #dt-program .v-data-table > .v-data-table__wrapper > table {
    border-spacing: 0 10px !important;
  }
  #dt-program .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 80px !important;
  }
  .theme--light #dt-program .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th{
    border:0px solid!important;
  }
  .theme--light #dt-program .v-data-table .v-data-footer {
    border:0px solid!important;    
  }
  #dt-program .v-data-footer__select {
    justify-content: left !important;
    position: absolute;
    left: 2%;
  }
  .v-text-field--outlined fieldset{
    border:0px solid;
  }
  .v-application--is-ltr #dt-program .v-data-footer__select .v-select {
    margin: 13px 0 13px 0px;
  }
</style>